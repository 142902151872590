<template>
    <SectionWrapper
        :action-button-options="actionButtonOptions"
        @action="UPDATE_MODAL_ACTIVE(true)">
        <template #sticky>
            <DomainsFilters></DomainsFilters>
        </template>

        <DomainsTable></DomainsTable>
    </SectionWrapper>
</template>

<script>
  import DomainsFilters from "@/components/Admin/Domains/DomainsFilters";
  import DomainsTable from "@/components/Admin/Domains/DomainsTable";
  import SectionWrapper from "@/components/Common/SectionWrapper";
  import {
    SET_EMPTY,
    UPDATE_MODAL_ACTIVE
  } from "@core/store/mutation-constants";
  import { mapActions } from "vuex";

  export default {
    name: "Domains",
    components: {
      DomainsTable,
      DomainsFilters,
      SectionWrapper
    },

    computed: {
      actionButtonOptions () {
        return {
          label: this.$t("admin.domains.tooltip.addDomain")
        };
      }
    },

    methods: {
      ...mapActions("admin/domains", {
        UPDATE_MODAL_ACTIVE: `domainModal/${ UPDATE_MODAL_ACTIVE }`,
        SET_EMPTY
      })
    },

    beforeDestroy () {
      this.SET_EMPTY();
    }
  };
</script>

<style scoped></style>
