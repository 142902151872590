var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('TablePlaceholder',{attrs:{"data":_vm.domains},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
var count = ref.count;
return [_c('LTable',{attrs:{"data":items,"loading":_vm.isLoading.getDomains,"count":count,"backend-pagination":"","query-pagination":""},on:{"update":_vm.GET_DOMAINS}},[_c('b-table-column',{attrs:{"label":_vm.$t("admin.domains.table.labels.name")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('LTooltip',{attrs:{"label":row.name,"active":row.name.length >= 37,"interactive":"","type":"is-white","position":"is-top-start"}},[_c('div',{staticClass:"truncate ellipsis",staticStyle:{"max-width":"200px"}},[_vm._v(" "+_vm._s(_vm.formatEmptyString(row.name))+" ")])])]}}],null,true)}),_c('b-table-column',{attrs:{"label":_vm.$t("admin.domains.table.labels.webmaster")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('WebmasterOutput',{attrs:{"webmaster":row.webmaster}})]}}],null,true)}),_c('b-table-column',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('LabelWithAnnotation',{attrs:{"tooltip":_vm.$t("admin.domains.table.tooltip.ssl"),"tooltip-position":"is-top","type":"is-info"}},[_vm._v(" "+_vm._s(_vm.$t("admin.domains.table.labels.sslAuthMethod"))+" ")])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.formatEmptyString(row.sslAuthMethod))+" ")]}}],null,true)}),_c('b-table-column',{attrs:{"label":_vm.$t("admin.domains.table.labels.certificateStatus"),"centered":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.formatEmptyString( _vm.$t(("common.entity.domains.filters.values.certificateStatusEnum." + (row.certificateStatus))) ))+" ")]}}],null,true)}),_c('b-table-column',{attrs:{"label":_vm.$t("admin.domains.table.labels.date"),"centered":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.formatEmptyString(_vm.date(row.createdAt)))+" ")]}}],null,true)}),_c('b-table-column',{attrs:{"label":_vm.$t("admin.domains.table.labels.action"),"centered":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('LTooltip',{attrs:{"label":_vm.$t("common.entity.tileItem.tooltip.delete"),"position":"left","type":"is-danger"}},[_c('b-button',{staticClass:"bulma-button",attrs:{"loading":_vm.isLoading.deleteDomain && _vm.loadingDeleteDomainId === row.id,"type":"is-danger is-light","icon-left":"trash-alt","pack":"far","size":"is-small"},on:{"click":function($event){return _vm.deleteDomain(row)}}})],1)]}}],null,true)})],1)]}},{key:"placeholder",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("common.table.noData"))+" ")]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }