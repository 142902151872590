<template>
    <LFilters
        :filters="filters"
        :loading="isLoading"
        @clear="SET_EMPTY('filters')"
        @confirm="confirmFilters">
        <template #filters>
            <div class="columns is-multiline is-real-mobile-flex">
                <FilterWrapper>
                    <b-field :label="$t('common.entity.filters.name')">
                        <LInput
                            v-model="name"
                            :placeholder="$t(`common.entity.filters.name`)">
                        </LInput>
                    </b-field>
                </FilterWrapper>

                <AccessControlFilter
                    v-model="webmasterId"
                    type="webmaster"
                    :placeholder="$t(`common.entity.filters.webmaster`)">
                </AccessControlFilter>

                <FilterWrapper>
                    <b-field :label="$t('admin.domains.filters.sslAuthMethod')">
                        <Select
                            v-model="sslAuthMethod"
                            :placeholder="$t(`admin.domains.filters.sslAuthMethod`)"
                            :get-data-vuex="getSslAuthMethod"
                            :searchable="false"
                            class="white"
                            field="label"
                            prop="value">
                        </Select>
                    </b-field>
                </FilterWrapper>
                <FilterWrapper>
                    <b-field :label="$t('admin.domains.filters.certificateStatus')">
                        <Select
                            v-model="certificateStatus"
                            :placeholder="$t(`admin.domains.filters.certificateStatus`)"
                            :get-data-vuex="getCertificateStatus"
                            :searchable="false"
                            class="white"
                            field="label"
                            prop="value">
                            <template #text="{ option }">
                                <span v-t="option.label"></span>
                            </template>
                            <template #selected-option="{ option }">
                                <span v-t="option.label"></span>
                            </template>
                        </Select>
                    </b-field>
                </FilterWrapper>

                <DomainsNewDomainModal :is-active.sync="isActiveModal_">
                </DomainsNewDomainModal>
            </div>
        </template>
    </LFilters>
</template>

<script>
  import AccessControlFilter from "@/components/Admin/Select/AccessControlFilter.vue";
  import DomainsNewDomainModal from "@/components/Admin/Domains/DomainsNewDomainModal";
  import sslAuthMethodList from "@core/store/modules/common/domains/datasets/sslAuthMethod.json";
  import certificateStatus from "@core/store/modules/common/domains/datasets/certificateStatus.json";
  import LFilters from "@/components/Common/LFilters";
  import Select from "@/components/Common/Select/Select.vue";
  import {
    SET_EMPTY,
    UPDATE_FILTERS,
    UPDATE_MODAL_ACTIVE
  } from "@core/store/mutation-constants";
  import { GET_DOMAINS } from "@core/store/action-constants";
  import { mapFields } from "@vasiliyrusin/vue-mapfields";
  import { mapActions, mapState } from "vuex";

  export default {
    name: "DomainsFilters",
    components: {
      AccessControlFilter,
      DomainsNewDomainModal,
      LFilters,
      Select
    },

    computed: {
      ...mapFields("filtersConfirm", {
        fields: ["name", "webmasterId", "sslAuthMethod", "certificateStatus"],
        base: "filters",
        action: UPDATE_FILTERS
      }),

      ...mapState("admin/domains", {
        isActiveModal: ({ domainModal }) => domainModal.isActiveModal,
        filters: ({ filters }) => filters
      }),

      isActiveModal_: {
        get () {
          return this.isActiveModal;
        },
        set (value) {
          this.UPDATE_MODAL_ACTIVE(value);
        }
      },

      isLoading () {
        return this.$wait(`admin/domains/${ GET_DOMAINS }`);
      }
    },

    methods: {
      ...mapActions("admin/domains", {
        UPDATE_MODAL_ACTIVE: `domainModal/${ UPDATE_MODAL_ACTIVE }`,
        UPDATE_FILTERS,
        GET_DOMAINS,
        SET_EMPTY
      }),

      confirmFilters ({ filters }) {
        this.UPDATE_FILTERS(filters);
        this.GET_DOMAINS();
      },

      getSslAuthMethod () {
        return {
          items: sslAuthMethodList,
          count: sslAuthMethodList.length
        };
      },

      getCertificateStatus () {
        return {
          items: certificateStatus,
          count: certificateStatus.length
        };
      }
    }
  };
</script>

<style scoped>

</style>
