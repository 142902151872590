<template>
    <TablePlaceholder :data="domains">
        <template #default="{ items, count }">
            <LTable
                :data="items"
                :loading="isLoading.getDomains"
                :count="count"
                backend-pagination
                query-pagination
                @update="GET_DOMAINS">
                <b-table-column
                    v-slot="{ row }"
                    :label="$t(`admin.domains.table.labels.name`)">
                    <LTooltip
                        :label="row.name"
                        :active="row.name.length >= 37"
                        interactive
                        type="is-white"
                        position="is-top-start">
                        <div
                            class="truncate ellipsis"
                            style="max-width: 200px;">
                            {{ formatEmptyString(row.name) }}
                        </div>
                    </LTooltip>
                </b-table-column>

                <b-table-column
                    v-slot="{ row }"
                    :label="$t(`admin.domains.table.labels.webmaster`)">
                    <WebmasterOutput :webmaster="row.webmaster"></WebmasterOutput>
                </b-table-column>

                <b-table-column>
                    <template #header>
                        <LabelWithAnnotation
                            :tooltip="$t(`admin.domains.table.tooltip.ssl`)"
                            tooltip-position="is-top"
                            type="is-info">
                            {{ $t(`admin.domains.table.labels.sslAuthMethod`) }}
                        </LabelWithAnnotation>
                    </template>
                    <template #default="{ row }">
                        {{ formatEmptyString(row.sslAuthMethod) }}
                    </template>
                </b-table-column>

                <b-table-column
                    v-slot="{ row }"
                    :label="$t(`admin.domains.table.labels.certificateStatus`)"
                    centered>
                    {{ formatEmptyString(
                        $t(`common.entity.domains.filters.values.certificateStatusEnum.${ row.certificateStatus }`)
                    ) }}
                </b-table-column>

                <b-table-column
                    v-slot="{ row }"
                    :label="$t(`admin.domains.table.labels.date`)"
                    centered>
                    {{ formatEmptyString(date(row.createdAt)) }}
                </b-table-column>

                <b-table-column
                    v-slot="{ row }"
                    :label="$t(`admin.domains.table.labels.action`)"
                    centered>
                    <LTooltip
                        :label="$t(`common.entity.tileItem.tooltip.delete`)"
                        position="left"
                        type="is-danger">
                        <b-button
                            :loading="isLoading.deleteDomain && loadingDeleteDomainId === row.id"
                            type="is-danger is-light"
                            class="bulma-button"
                            icon-left="trash-alt"
                            pack="far"
                            size="is-small"
                            @click="deleteDomain(row)">
                        </b-button>
                    </LTooltip>
                </b-table-column>
            </LTable>
        </template>
        <template #placeholder>
            {{ $t(`common.table.noData`) }}
        </template>
    </TablePlaceholder>
</template>

<script>
  import TablePlaceholder from "@/components/Common/Table/TablePlaceholder";
  import LabelWithAnnotation from "@/components/Common/Tooltip/LabelWithAnnotation.vue";
  import WebmasterOutput from "@/components/Common/Output/WebmasterOutput.vue";
  import LTable from "@/components/Common/LTable";
  import { mapActions, mapState } from "vuex";
  import { DELETE_DOMAIN, GET_DOMAINS } from "@core/store/action-constants";
  import { actionWithToast } from "@/helpers/actionWithToast";
  import { formatEmptyString } from "@core/filters";
  import momentjs from "moment";

  export default {
    name: "DomainsTable",

    components: {
      LabelWithAnnotation,
      TablePlaceholder,
      WebmasterOutput,
      LTable
    },

    data () {
      return {
        loadingDeleteDomainId: null
      };
    },

    computed: {
      ...mapState("admin/domains", {
        domains: ({ domains }) => domains
      }),

      isLoading () {
        return {
          getDomains: this.$wait(`admin/domains/${ GET_DOMAINS }`),
          deleteDomain: this.$wait(`admin/domains/${ DELETE_DOMAIN }`)
        };
      }
    },

    methods: {
      ...mapActions("admin/domains", [
        DELETE_DOMAIN,
        GET_DOMAINS
      ]),

      async deleteDomain ({ id }) {
        const { dialog } = await this.$buefy.dialog.confirm({
          title: this.$t("common.entity.confirm.header.delete"),
          message: this.$t("common.entity.confirm.body.delete"),
          confirmText: this.$t("common.entity.confirm.footer.confirmDelete"),
          cancelText: this.$t("common.entity.confirm.footer.cancel"),
          type: "is-danger is-light",
          closeOnConfirm: false,
          onConfirm: async () => {
            try {
              this.loadingDeleteDomainId = id;
              await actionWithToast(this.DELETE_DOMAIN(id), "common.messages.domain.delete");
            } finally {
              this.loadingDeleteDomainId = null;
            }
          }
        });

        dialog.close();
      },

      date (date) {
        return momentjs(date).format("DD.MM.YYYY HH:mm");
      },

      formatEmptyString
    }
  };
</script>

<style></style>
