<template>
    <ModalBox
        :is-active.sync="isModalActive"
        @close="closeModal"
        @submit="addDomain">
        <template #header>
            <h5 class="title is-5">
                {{ $t("admin.domains.modal.header.addDomain") }}
            </h5>
        </template>

        <section>
            <div class="mb-5">
                <b-field>
                    <template #label>
                        <div class="is-flex pt-1">
                            {{ $t('admin.domains.modal.body.domainName') }}
                            <LabelWithAnnotation
                                :tooltip="$t(`admin.domains.modal.messages.domainName`)"
                                tooltip-position="is-top"
                                type="is-info">
                            </LabelWithAnnotation>
                        </div>
                    </template>
                    <LInput
                        v-model="name"
                        :placeholder="$t('admin.domains.modal.body.domainName')"
                        :pattern="DOMAIN_NAME_VALIDATOR"
                        :validation-message="$t(`admin.domains.modal.notifications.invalidName`)"
                        required>
                    </LInput>
                </b-field>
            </div>

            <div class="mb-5">
                <b-field :label="$t('admin.domains.modal.body.sslAuthMethod')">
                    <Select
                        v-model="sslAuthMethod"
                        :placeholder="$t(`admin.domains.modal.body.sslAuthMethod`)"
                        :get-data-vuex="getSslAuthMethod"
                        :searchable="false"
                        required
                        class="white"
                        field="label"
                        prop="value">
                    </Select>
                </b-field>
            </div>

            <div>
                <b-field :label="$t('admin.domains.modal.body.webmaster')">
                    <WebmastersSelect
                        v-model="webmasterId"
                        route-param="webmasterId"
                        namespace-module="admin/domains/domainModal/webmastersFilters"
                        :placeholder="$t(`admin.domains.modal.body.webmaster`)">
                    </WebmastersSelect>
                </b-field>
            </div>
        </section>

        <template #footer>
            <div class="container">
                <div class="columns">
                    <div class="column w-full">
                        <b-button
                            :loading="isLocalLoading"
                            class="is-fullwidth"
                            native-type="submit"
                            type="is-success is-light">
                            {{ $t("common.buttons.save") }}
                        </b-button>
                    </div>
                </div>
            </div>
        </template>
    </ModalBox>
</template>

<script>
  import sslAuthMethodList from "@core/store/modules/common/domains/datasets/sslAuthMethod.json";
  import { DOMAIN_NAME_VALIDATOR } from "@core/validators";
  import Select from "@/components/Common/Select/Select.vue";
  import ModalBox from "@/components/Common/Modals/ModalBox.vue";
  import { Modal } from "@core/mixins";
  import {
    ADD_DOMAIN,
    GET_DOMAINS,
    UPDATE_OPTIONS,
    SET_EMPTY
  } from "@core/store/action-constants";
  import { mapFields } from "@vasiliyrusin/vue-mapfields";
  import { mapActions } from "vuex";
  import LabelWithAnnotation from "@/components/Common/Tooltip/LabelWithAnnotation.vue";
  import { actionWithToast } from "@/helpers/actionWithToast";
  import WebmastersSelect from "@/components/Common/Select/WebmastersSelect.vue";

  export default {
    name: "DomainsNewDomainModal",
    
    mixins: [Modal],
    
    components: {
      LabelWithAnnotation,
      WebmastersSelect,
      ModalBox,
      Select
    },
    
    computed: {
      ...mapFields("admin/domains/domainModal", {
        fields: [
          "name",
          "sslAuthMethod",
          "webmasterId"
        ],
        base: "options",
        action: UPDATE_OPTIONS
      }),

      DOMAIN_NAME_VALIDATOR () {
        return DOMAIN_NAME_VALIDATOR;
      },

      isLocalLoading () {
        return this.$wait(`admin/domains/domainModal/${ ADD_DOMAIN }`);
      }
    },

    methods: {
      ...mapActions("admin/domains", [
        GET_DOMAINS
      ]),

      ...mapActions("admin/domains/domainModal", [
        UPDATE_OPTIONS,
        ADD_DOMAIN,
        SET_EMPTY
      ]),

      closeModal () {
        this.isModalActive = false;
        this.SET_EMPTY();
      },
      
      async addDomain () {
        await actionWithToast(this.ADD_DOMAIN(), "common.messages.domain.create");
        this.closeModal();
      },

      getSslAuthMethod () {
        return {
          items: sslAuthMethodList,
          count: sslAuthMethodList.length
        };
      }
    }
  };
</script>

<style scoped>
</style>
