<template>
    <Domains></Domains>
</template>

<script>
  import Domains from "@/components/Admin/Domains/Domains";
  
  export default {
    name: "DomainsView",
    components: { Domains }
  };
</script>

<style scoped>

</style>